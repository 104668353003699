<template>
  <el-dialog
    :visible="show"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    title="New Group"
  >
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      @submit.native.prevent
    >
      <el-form-item label="Name" prop="name">
        <el-input v-model="form.name" />
      </el-form-item>
      <el-form-item label="Description" prop="description">
        <el-input
          v-model="form.description"
          type="textarea"
          :rows="3"
        />
      </el-form-item>
      <el-form-item v-if="isReporting" prop="contains_all_properties">
        <el-checkbox v-model="form.contains_all_properties">
          This group should always contain all properties in Company
        </el-checkbox>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="cancel">
        Cancel
      </el-button>
      <el-button
        type="primary"
        @click="validateAndSubmit"
      >
        Create
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import { constants } from '@/utils/constants'

export default {
  name: 'GroupAddDialog',
  props: {
    show: {
      type: Boolean,
      required: true
    },
    isReporting: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      form: {
        name: '',
        description: '',
        contains_all_properties: true
      },
      rules: {
        name: [
          { required: true, message: 'This field is required', trigger: 'blur' },
          { max: 128, message: 'Name cannot be more than 128 characters', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.constants = constants
  },
  methods: {
    validateAndSubmit () {
      this.$refs['form'].validate(valid => {
        if (valid) {
          const data = JSON.parse(JSON.stringify(this.form))
          this.$emit('submit', data)
          this.reset()
        }
      })
    },
    cancel () {
      this.$emit('close')
      this.reset()
    },
    reset () {
      this.$refs['form'].resetFields()
      this.$refs['form'].clearValidate()
    }
  }
}
</script>
