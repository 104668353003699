<template>
  <div v-loading="loading">
    <el-row type="flex" justify="end">
      <el-button
        icon="el-icon-plus"
        type="primary"
        size="medium"
        @click="showAddGroupDialog = true"
      >
        Add Group
      </el-button>
    </el-row>
    <el-row>
      <data-table :data="groups" class="e2e-group-list-table">
        <el-table-column
          label="Group Name"
          prop="name"
          sortable
        >
          <template slot-scope="scope">
            <div
              v-if="isReporting"
            >
              <i v-if="scope.row.contains_all_properties" class="el-icon-star-on" />
              <link-button
                :to="{ name: 'GroupStructure', params: { gid: scope.row.id } }"
                type="primary"
                class="group-link"
              >
                {{ scope.row.name }}
              </link-button>
            </div>
            <div v-else>
              <i v-if="!scope.row.parent" class="el-icon-star-on" />
              {{ scope.row.name }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="Members"
          prop="member_count"
          sortable
        />
        <el-table-column
          label="Properties"
          prop="propertyCount"
          sortable
        />
        <el-table-column
          v-if="isReporting"
          label="Activation Code"
          prop="activation_code"
        />
        <el-table-column align="center" width="120">
          <template slot-scope="scope">
            <el-button type="text" @click="editGroup(scope.row)">
              Edit Group
            </el-button>
          </template>
        </el-table-column>
      </data-table>
    </el-row>

    <group-add-dialog
      :show="showAddGroupDialog"
      :is-reporting="isReporting"
      @close="showAddGroupDialog = false"
      @submit="createGroup"
    />
    <group-edit
      :group="groupToEdit"
      :type="$route.meta.type || ''"
      :visible.sync="showEditGroupDrawer"
      @close="showEditGroupDrawer = false"
      @delete="showGroupDeleteDialog = true"
      @refresh="refreshEdit"
    />
    <group-delete-dialog
      :show="showGroupDeleteDialog"
      :group="groupToEdit"
      @close="showGroupDeleteDialog = false"
      @delete="deleteGroupHandler(groupToEdit)"
    />
  </div>
</template>

<script>
import DataTable from '@/components/tables/DataTable'
import GroupAddDialog from './_components/GroupAddDialog'
import GroupEdit from './_components/GroupEdit/GroupEdit'
import GroupDeleteDialog from './_components/GroupDeleteDialog'
import LinkButton from '@/components/buttons/LinkButton'
import CraigslistAPI from '@/services/api/craigslist'
import InvoicesAPI from '@/services/api/invoices'
import { enums } from '@/utils/constants'
import { propertyCount } from '@/utils/groups'

export default {
  name: 'GroupList',
  components: {
    'data-table': DataTable,
    'group-add-dialog': GroupAddDialog,
    'group-edit': GroupEdit,
    'group-delete-dialog': GroupDeleteDialog,
    'link-button': LinkButton
  },
  data () {
    return {
      groups: [],
      showAddGroupDialog: false,
      showEditGroupDrawer: false,
      groupToEdit: {},
      showGroupDeleteDialog: false,
      loading: false
    }
  },
  computed: {
    api () {
      return this.$route.meta.type === enums.groupTypes.REPORTING ? CraigslistAPI : InvoicesAPI
    },
    isReporting () {
      return this.$route.meta.type === enums.groupTypes.REPORTING
    }
  },
  created () {
    this.fetchGroups()
  },
  methods: {
    /**
     * Fetches list of groups from the api.
     */
    async fetchGroups () {
      try {
        this.loading = true
        const params = {
          company_id: this.$route.params.cid
        }
        const response = await this.api.groups.list(params)

        const data = []
        if (response.length) {
          // a company should always only have one top level group (for each type) that is hidden from the UI.
          const hiddenGroup = response[0]

          // expose top level invoicing group
          if (!this.isReporting) {
            hiddenGroup.name = `${hiddenGroup.company.human_name} Entire Company`
            hiddenGroup.description = 'This is the default invoicing group for the entire company. Recipients of this group will receive the summary for all properties in this company. This group cannot be deleted.'
            data.push({
              ...hiddenGroup,
              propertyCount: propertyCount(hiddenGroup)
            })
          }

          for (const group of hiddenGroup.children) {
            data.push({
              ...group,
              propertyCount: propertyCount(group)
            })
          }
        }
        this.groups = data
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
      } finally {
        this.loading = false
      }
    },
    /**
     * Creates new group.
     *
     * @param {Object} form - form fields from the Group Add dialog
     */
    async createGroup (form) {
      this.showAddGroupDialog = false
      form.properties = []
      form.company = { id: parseInt(this.$route.params.cid) }
      try {
        this.loading = true
        await this.api.groups.create(form)
        this.$message({
          message: `Group ${form.name} created`,
          type: 'success',
          duration: 3500
        })
        this.fetchGroups()
      } catch (err) {
        this.loading = false
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
      }
    },
    /**
     * Opens the edit group drawer.
     *
     * @param {Object} group - row object
     */
    editGroup (group) {
      this.groupToEdit = group
      this.showEditGroupDrawer = true
    },
    /**
     * Handler for when delete is clicked from confirmation dialog.
     *
     * @param {Object} group
     */
    deleteGroupHandler (group) {
      this.showEditGroupDrawer = false
      this.showGroupDeleteDialog = false
      const params = {
        force_delete_children: true
      }
      this.deleteGroup(group, params)
    },
    /**
     * Calls API to delete a Group.
     *
     * @param {Object} group
     */
    async deleteGroup (group, params) {
      this.loading = true
      try {
        await this.api.groups.delete(group.id, params)
        this.$message({
          message: `Group ${group.name} deleted.`,
          type: 'success',
          duration: 3500
        })
        this.fetchGroups()
      } catch (err) {
        this.loading = false
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
      }
    },
    /**
     * Re-fetches the groups and the group being edited.
     */
    async refreshEdit () {
      await this.fetchGroups()
      this.groupToEdit = this.groups.find(group => group.id === this.groupToEdit.id)
      this.$emit('refresh')
    }
  }
}
</script>

<style lang="scss" scoped>
i + .group-link {
  margin-left: 0.25em;
}
</style>
